import styled from "@emotion/styled"

import StyledFlex, { StyledFlexChild } from "../flex/flex-styles"
import StyledSection from "../section/section-styles"

const StyledFoundingPrinciples = styled(StyledFlex)`
  flex-wrap: wrap;
  align-items: center;
`

export const StyledFoundingPrincipleSection = styled(StyledFlexChild)`
  margin-right: 1em;
  flex: 300px;

  @media (max-width: 759px) {
    .founding-img {
      margin-bottom: 1em;
    }
  }
`

export const StyledPrinciplesContainer = styled(StyledSection)`
  padding-top: 3em;
  padding-bottom: 3em;
`

export default StyledFoundingPrinciples

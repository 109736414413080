import React from "react"
import { useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"

import { Layout, SEO, Container, Divider, Team } from "../components"

import StyledFoundingPrinciples, {
  StyledFoundingPrincipleSection,
  StyledPrinciplesContainer,
} from "../components/styles/about"

const About = ({ location }) => {
  const data = useStaticQuery(graphql`
    query TeamImages {
      Faith_Umah: file(relativePath: { eq: "Faith.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Taiwo_Abraham: file(relativePath: { eq: "Taiwo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Other_Team_Members: file(relativePath: { eq: "others.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Founding_Principles: file(
        relativePath: { eq: "founding_principles.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const teams = [
    {
      name: "Faith Umah",
      description: (
        <>
          <p>
            <b>Faith Umah</b>, a Corporate Attorney and Management Consultant
            has been a Corporate Management and Legal Consultant from 2009 till
            present. Her eccentric consulting engagements have spanned through
            industry specifics ranging from Oil and Gas, Real Estate, I.T,
            Fintech, Fashion, Government, Tourism, Medical, Hospitality, amongst
            others
          </p>

          <p>
            Her clientele ranges from Public sector to the Private sector, local
            to international. She also has her niches in Intellectual Property,
            Finance Law and ICT Law. Her hybrid of Corporate Law and Business
            Management Advisory skills make her a force to reckon with
          </p>

          <p>
            Also a <b>Harvard Business School</b> Trained Business Professional,
            she's passionate about helping people and businesses win.
          </p>
        </>
      ),
      avatarURL: data["Faith_Umah"],
    },
    {
      name: "Taiwo Abraham",
      description: (
        <>
          <p>
            <b>Taiwo Abraham</b>, a 2020 PMI Future 50 honoree, is a globally
            acclaimed Project Management expert, influencer, thought leading
            researcher, coach, speaker and result-driven organization
            productivity enthusiast. He combines skill sets in managing complex
            programs, optimizing technology for business value, and leading
            organization change/transformation with mastery of both traditional
            waterfall and Agile methodologies. For about 15 years, he has
            delivered productivity enhancement projects for optimum value
            delivery to customers & stakeholders in both private and public
            sectors, including the Central Bank of Nigeria and UNESCO, etc.{" "}
          </p>
          <p>
            He earned his bachelor’s degree in Obafemi Awolowo University,
            Nigeria and later with a perfect GPA of 4.0, he received his MBA at
            the U of Texas (UTRGV). He is a certified PMP(R), Scrum Master,
            Disciplined Agilist, PRINCE 2P, ITIL Expert, Lean Six Sigma Green
            Belt. He recently concluded his work with the PMI as part of a team
            of industry professionals selected to develop the 7th edition of the
            PMBOK(R) guide.
          </p>
          <p>
            Currently inline for a GFEL’s 2020 award as one of top 100
            management education leaders in the world, in 2019, he was awarded
            the Project Management Institute’s Young Professional of the Year.
            This came right after 2018, when he was awarded the PMI's James R.
            Snyder Award for his unique contribution to the global PM community
            through his research work.
          </p>
        </>
      ),
      avatarURL: data["Taiwo_Abraham"],
    },
  ]

  // For about 15 years, he has delivered
  // productivity enhancement projects for optimum value delivery to customers/stakeholders in both
  // private and public sectors, including the Central Bank of Nigeria and UNESCO, etc.

  return (
    <Layout
      location={location}
      title="Offering Excellent Experiences, One Client at a Time."
      subTitle="OUR MISSION"
    >
      <SEO
        title="About Us"
        description="Our services are tailored to offer excellent experiences, one client at a time."
      />
      <Container>
        <p className="max-width-800">
          LightVille was founded to fix frictions in organizations, help
          corporate bodies build and grow sustainable businesses, and partner
          with its clients to build category kings in their respective
          industries.
        </p>

        <p className="max-width-800">
          <span className="accent vision">Our Vision</span>: We want to become a
          second-to-none consulting brand, helping establish category kings
          spanning across industry specific.
        </p>

        <Divider />
      </Container>

      <Team teams={teams} />

      <Container>
        <div className="max-width-800">
          <h3>And The Others...</h3>

          <Img fluid={data.Other_Team_Members.childImageSharp.fluid} />

          <p className="max-width-800">
            We have, over the years, articulated play books for numerous leading
            Financial Institutions in Africa. LightVille specialist possess
            real-world experience in project management and strategy
            implementation support, business/operations strategy and start-up
            advisory. We garner experience that covers macroeconomic and
            statistical analysis, financial modelling, strategy analysis and
            design, business case development and synergy analysis.
          </p>
        </div>
      </Container>

      <Container>
        <Divider />
      </Container>

      <Container>
        <Fade duration={500}>
          <StyledPrinciplesContainer>
            <StyledFoundingPrinciples>
              <StyledFoundingPrincipleSection>
                <Img
                  className="founding-img"
                  fluid={data.Founding_Principles.childImageSharp.fluid}
                />
              </StyledFoundingPrincipleSection>
              <StyledFoundingPrincipleSection className="max-width-500">
                <h2>Founding Principles</h2>
                <p>
                  With the present velocity and volume of change ongoing in the
                  world, alongside technological advancement that is almost now
                  an indispensable tool in every industry, corporations,
                  government, individuals, organizations are burdened with the
                  ability to keep pace as well as run their businesses to
                  achieve their strategic objectives. LightVille takes such
                  burden off them, availing these ones with its arsenals to
                  thrive in their respective fields and win.
                </p>
                <p>
                  Basically LightVille is geared towards fixing its market
                  frictions in a style of Excellence. LightVille was birthed on
                  a passion to fix frictions; we practically channel this
                  passion into every single engagement we get.
                </p>
              </StyledFoundingPrincipleSection>
            </StyledFoundingPrinciples>
          </StyledPrinciplesContainer>
        </Fade>
      </Container>
    </Layout>
  )
}

export default About
